/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import Link from 'next/link'
import { Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import ButtonComponent from 'src/components/shared/atoms/button'
import clsx from 'clsx'
import useStyles from './styles'

interface HelpLinesCardProps {
  onClickHandler?: () => void
}

const HelplineCard: React.FC<HelpLinesCardProps> = ({
  onClickHandler,
}: HelpLinesCardProps) => {
  const { classes } = useStyles()
  return (
    <>
      <Card className={classes.root}>
        <CardContent sx={{ width: '100%' }}>
          <Typography component="h2" className={classes.cardContentHeading}>
            Are You OK?
          </Typography>
          <Typography
            component="p"
            variant="body1"
            className={classes.cardContentP}
          >
            We have identified certain keywords of a sensitive nature within
            your search inquiries. Should you require any assistance, please do
            not hesitate to refer to the provided helplines or get in touch with
            our support team.
          </Typography>
          <br />
        </CardContent>
        <CardActions className={classes.cardContent}>
          <Link href="/helplines" className={classes.links}>
            <ButtonComponent
              type={'contained'}
              text="Get Help Now"
              gradient
              className={classes.btnHover}
            />
          </Link>
          <ButtonComponent
            type={'contained'}
            text="I'm OK!"
            className={clsx(classes.btnHover, classes.okBtn)}
            onClick={onClickHandler}
          />
        </CardActions>
      </Card>
    </>
  )
}

export default HelplineCard
