/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import { Grid } from '@mui/material'
import { CrossIcon } from 'src/assets/svgs'

import useStyles from './styles'

interface ModalType {
  closeHandler?: () => void
  children?: React.ReactNode
}

const Modal: React.FC<ModalType> = ({ closeHandler, children }: ModalType) => {
  const { classes } = useStyles()
  return (
    <>
      <Grid container className={classes.root}>
        <span className={classes.closeIcon} onClick={closeHandler}>
          <CrossIcon />
        </span>
        <Grid container item className={classes.innerContainer}>
          {children}
        </Grid>
      </Grid>
    </>
  )
}

export default Modal
