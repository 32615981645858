import * as React from 'react'
import { DotLottiePlayer } from '@dotlottie/react-player'
import '@dotlottie/react-player/dist/index.css'

interface Props {
  pageName: string
}

function HeaderLottie({ pageName = 'home' }: Props) {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 900)
  const [isChrome, setIsChrome] = React.useState(false)

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900)
    }

    const handleBrowserCheck = () => {
      const userAgent = window.navigator.userAgent
      const isChromeBrowser =
        /Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor)
      setIsChrome(isChromeBrowser)
    }

    handleBrowserCheck()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (isMobile || !isChrome) {
    return (
      <div className="bannerHeroVideo">
        <video
          id="myVideo"
          style={{ position: 'absolute' }}
          muted
          playsInline
          autoPlay
          loop
        >
          <source
            src={pageName === 'home' ? '/Hero.mp4' : '/hub-hero-1.mp4'}
            type="video/mp4"
          />
        </video>
      </div>
    )
  }

  return (
    <div
      className="bannerHeroVideo"
      style={{ position: 'absolute', width: '100%' }}
    >
      <DotLottiePlayer
        src={
          pageName === 'home'
            ? 'https://lottie.host/5f7e7f8f-c835-4ebc-9595-d99d7dbfd11e/NMxHjAoAmL.lottie'
            : 'https://lottie.host/0c18f66e-af8d-44af-9445-00e454e37d39/y8QESdZ5Sv.lottie'
        }
        autoplay
        loop
        style={{ width: '100%', opacity: pageName === 'home' ? 1 : 0.2 }}
      ></DotLottiePlayer>
    </div>
  )
}

export default HeaderLottie
