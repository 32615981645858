import { makeStyles } from 'tss-react/mui'
import { lato } from '@styles/fonts'
import { nunito_sans } from '@styles/fonts'

const useStyles = makeStyles()((theme) => ({
  rootContainer: {
    padding: '6.5rem 0rem 6.5rem 0rem',
    position: 'relative',
    overflow: 'hidden',
    fontFamily: 'Nunito Sans',
    [theme.breakpoints.down('md')]: {
      padding: '6.5rem 0rem 4.5rem 0rem',
    },
  },

  bgWithCss: {
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '651px',
      height: '425.18px',
      left: '-221.77px',
      top: '19px',
      background:
        'radial-gradient(81.18% 124.3% at 10.29% 52.02%, rgba(231, 38, 191, 0.4) 0%, rgba(253, 206, 173, 0.4) 100%) ',
      filter: 'blur(27px)',
      transform: 'rotate(21.99deg)',
      zIndex: '-1',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '414.22px',
      height: '270.53px',
      right: '-30px',
      top: '92.26px',

      background:
        'radial-gradient(81.18% 124.3% at 10.29% 52.02%, rgba(231, 38, 191, 0.4) 0%, rgba(253, 206, 173, 0.4) 100%)',
      filter: 'blur(27px)',
      transform: ' rotate(-11.17deg)',
      zIndex: '-1',
    },
  },

  contentContainer: {
    textAlign: 'center',
    width: '90%',
  },

  popularContainer: {
    width: '50%',
    margin: '28px auto',
    postion: 'relative',
    textAlign: 'left',
    '@media (max-width:1250px)': {
      width: '60%',
    },
    '@media (max-width:978px)': {
      width: '70%',
    },
    '@media (max-width:780px)': {
      width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  getHelpInput: {
    height: '76px',
    [theme.breakpoints.down('sm')]: {
      height: '61px',
    },
  },

  inputAdornmentStart: {
    marginLeft: '20px',
    marginTop: '4px',
  },

  dropDownBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    borderLeft: '1px solid black',
    width: '178px',
    alignItems: 'center',
    paddingLeft: '17px',
    height: '64px',
    [theme.breakpoints.down('sm')]: {
      height: '61px',
    },
    cursor: 'pointer',
    background: '#F3F3F3',
    borderTopRightRadius: '40px',
    '& .MuiPopover-paper': {
      padding: 0,
      width: '155px',
      marginLeft: '14.5px',
      borderRadius: 0,
      boxShadow: 'none',
      background: '#F3F3F3',
      border: '1px solid black',
      borderTop: 0,
      borderBottomRightRadius: theme.shape.borderRadius * 8,
    },
    '& *': {
      border: '0px !important',
    },
  },
  dropDownClose: {
    display: 'flex',
    justifyContent: 'space-between',
    borderLeft: '1px solid black',
    width: '178px',
    alignItems: 'center',
    paddingLeft: '17px',
    height: '64px',
    [theme.breakpoints.down('sm')]: {
      height: '61px',
      width: '110px',
    },
    cursor: 'pointer',
    borderTopRightRadius: '40px',
    borderBottomRightRadius: '40px',
    '& .MuiPopover-paper': {
      padding: 0,
      width: '155px',
      marginLeft: '14.5px',
      borderRadius: 0,
      boxShadow: 'none',
      background: '#F3F3F3',
      border: '1px solid black',
      borderTop: 0,
      borderBottomRightRadius: theme.shape.borderRadius * 8,
    },
    '& *': {
      border: '0px !important',
    },
  },
  dropDownText: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '21.82px',
    marginRight: '92px',
    color: '#000',
  },
  menu: {
    '& .MuiPaper-root': {
      padding: 0,
      width: '155px',
      marginLeft: '-.5px',
      borderRadius: 0,
      boxShadow: 'none',
      background: '#F3F3F3',
      border: '1px solid black',
      borderTop: 0,
      borderBottomRightRadius: theme.shape.borderRadius * 8,
    },
    '& .MuiMenuItem-root': {
      fontSize: '14px',
      lineHeight: '19.1px',
    },
  },
  aaPanel: {
    background: 'white',
    borderRadius: '20px',
    padding: '10px',
    zIndex: 999,
    marginTop: '-30px',
    marginBottom: '47px',
  },
  aaInput: {
    position: 'absolute',
    height: '50px',
    top: '8px',
    left: '50px',
    background: 'transparent',
    border: 'none',
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
    width: '39%',
    '@media (max-width:1378px)': {
      width: '35%',
    },
    '@media (max-width:1280px)': {
      width: '32%',
    },
    '@media (max-width:1140px)': {
      width: '30%',
    },
    '@media (max-width:890px)': {
      width: '52%',
    },
    '@media (max-width:860px)': {
      width: '50%',
    },
    '@media (max-width:840px)': {
      width: '48%',
    },
    '@media (max-width:820px)': {
      width: '52%',
    },
    '@media (max-width:690px)': {
      width: '50%',
    },
    '@media (max-width:680px)': {
      width: '62%',
    },
    '@media (max-width:530px)': {
      width: '60%',
    },
    '@media (max-width:460px)': {
      width: '55%',
    },
    '@media (max-width:385px)': {
      width: '50%',
    },
    '@media (max-width:355px)': {
      width: '46%',
    },
  },
  aaList: {
    listStyle: 'none',
    paddingLeft: '20px',
  },
  aaItem: {
    padding: '10px 0px',
    '&:hover': {
      background: 'lightgrey',
      cursor: 'pointer',
    },
  },
  signupField: {
    boxSizing: 'border-box',
    border: '0',
    width: '100%',
    height: '68px',
    marginBottom: '40px',
    // marginBottom: '28px',
    background: 'white',
    borderRadius: '40px',
    'input:-webkit-autofill': {
      boxShadow: '0 0 0px 1000px white inset !important',
    },
    color: 'black !important',

    '& ::-webkit-input-placeholder': {
      fontWeight: 900,
      lineHeight: '0px',
      fontStyle: 'normal',
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: '16px',
      marginRight: '20px',
      fontFamily: nunito_sans.style.fontFamily,
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    [theme.breakpoints.down('sm')]: {
      height: '61px',
    },
  },
  signupFieldCollapsed: {
    '& .MuiOutlinedInput-root': {
      borderBottomRightRadius: '40px',
      height: '67px',
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: 0,

      // height: '70px',
      [theme.breakpoints.down('sm')]: {
        height: '61px',
      },
      '& fieldset': {
        borderColor: 'black',

        height: '73px',
        [theme.breakpoints.down('sm')]: {
          height: '67px',
        },
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
        borderWidth: '1px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
        borderBottomRightRadius: '40px',
      },
    },
  },
  signupFieldExpanded: {
    '& .MuiOutlinedInput-root': {
      borderBottomRightRadius: '0px',
      height: '67px',
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: 0,
      [theme.breakpoints.down('sm')]: {
        height: '61px',
      },

      '& fieldset': {
        borderColor: 'black',
        height: '73px',
        [theme.breakpoints.down('sm')]: {
          height: '67px',
        },
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
        borderWidth: '1px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
        borderBottomRightRadius: '0px',
      },
    },
  },
  dropdownStyle: {
    color: ' rgba(0, 0, 0, 0.87)',
    webkitTransition: 'none',
    transition: 'none',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%),0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',

    position: 'absolute',
    overflowY: 'auto',
    overflowX: 'hidden',
    minWidth: '16px',
    minHeight: '16px',
    maxWidth: 'calc(100% - 32px)',
    maxHeight: 'calc(100% - 32px)',
    outline: 0,
    background: ' #f3f3f3',
    border: '1px solid black',
    borderTop: 0,
    borderRadius: 0,
    marginTop: '0px',
    borderBottomRightRadius: '40px',
  },
  // ? Hub Hero Header
  category: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '27px',
    textAlign: 'center',
    letterSpacing: '0.4em',
    textTransform: 'uppercase',
  },
  mainTitle: {
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '84px',
    lineHeight: '83px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.custom.blackColor,
    margin: theme.spacing(6, 0, 4, 0),
    [theme.breakpoints.down('md')]: {
      fontSize: '48px',
      lineHeight: '58px',
    },
  },
  subTitleContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subTitle: {
    maxWidth: '740px',
    fontFamily: lato.style.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#000000',
    opacity: '0.7',
  },

  heroText: {
    width: '960px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '84px',
    lineHeight: '90px',
    textAlign: 'center',
    textTransform: 'uppercase',
    backgroundImage: 'url(https://i.postimg.cc/gkmRVL2k/hero-Text-Fill-1.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  aiIcon: {
    marginRight: '14px',
    color: '#939393',
    marginTop: '2px',
    span: {
      color: 'black',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '@media (max-width:890px)': {
      display: 'none',
    },
  },
}))

export default useStyles
