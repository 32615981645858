import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    maxWidth: '560px',
    height: 'fit-content',
    maxHeight: '320px',
    padding: theme.spacing(4),
    margin: theme.spacing(4),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: 'white',
    color: 'black',
    borderRadius: '24px',
    border: `1px solid ${theme.custom.whiteColor}`,
    backdropFilter: 'blur(52px)',
    zIndex: 9999999,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%',
  },
  cardContentHeading: {
    color: theme.custom.blackColor,
    textAlign: 'start',
    fontFamily: 'Nunito Sans',
    fontSize: '42px',
    fontStyle: 'normal',
    fontWeight: 800,
    // lineHeight: '83px' /* 115.278% */,
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      fontSize: '24px',
    },
  },
  cardContentP: {
    color: theme.custom.blackColor,
    textAlign: 'start',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
  },
  btnHover: {
    '&:hover': {
      background: theme.custom.blackColor,
      color: theme.custom.whiteColor,
    },
  },
  okBtn: {
    width: 120,
    marginLeft: theme.spacing(4),
  },
  links: {
    textDecoration: 'none',
  },
}))

export default useStyles
