import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: 'rgba(2, 2, 2, 0.9)',
    backdropFilter: 'blur(12.8px)',
    color: theme.custom.lightFont,
    zIndex: 999999,
  },
  closeIcon: {
    position: 'fixed',
    top: 20,
    right: 40,
    width: theme.spacing(8),
    height: theme.spacing(8),
    cursor: 'pointer',
    zIndex: 9999990,
    [theme.breakpoints.down('md')]: {
      top: 15,
      right: 20,
    },
  },
  innerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0),
    },
  },
}))

export default useStyles
