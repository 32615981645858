import { makeStyles } from 'tss-react/mui'
import { nunito_sans } from '@styles/fonts'
const useStyles = makeStyles()(() => ({
  tagContainer: {
    fontFamily: nunito_sans.style.fontFamily,
    padding: '9px 17px',
    border: '1px solid #D9D9D9',
    borderRadius: '100px',
    marginRight: '12px',
    marginLeft: '0px !important',
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: '0.02em',
    fontWeight: 700,
    fontStyle: 'normal',
    color: '#000',
    marginBottom: '10px',
  },
}))

export default useStyles
