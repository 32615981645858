import React, { CSSProperties } from 'react'
import useStyles from './Style'
import { Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import { activeTopicHandler } from 'src/store/reducer/activeTopicSlice'

interface Props {
  id: number
  content: string
  color?: string
  background?: string
  borderColor?: string
  style?: CSSProperties
  onClick?: (e: React.SyntheticEvent, content?: string) => void
  onClickHandler?: boolean
}

const Tag: React.FC<Props> = ({
  id,
  content,
  color,
  background,
  borderColor,
  style,
  onClickHandler,
  onClick,
}) => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const handleClick = (e: React.SyntheticEvent) => {
    if (onClickHandler) {
      dispatch(activeTopicHandler({ id: id }))
    }

    if (onClick) {
      onClick(e)
    }
  }
  return (
    <Button
      onClick={handleClick}
      className={classes.tagContainer}
      style={{
        color: color && color,
        background: background && background,
        borderColor: borderColor && borderColor,
        ...style,
      }}
    >
      {content}
    </Button>
  )
}

export default Tag
